<template>
    <div class="head-menu">
        <el-space size="large" style="vertical-align: middle;">
            <el-dropdown class="hidden-sm-and-down">
                <span class="el-dropdown-link">
                    <span>{{username}}</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-if="username != '新疆运维'" @click="personinfo">个人资料</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-switch-button" @click="logout">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-space>
    </div>
</template>

<script>  
    import {onMounted, reactive, toRefs} from 'vue'
    import {useRouter} from 'vue-router'
    
    import Cookies from 'js-cookie'
    export default {
        
        data(){
            return{
                username: ''
            }
        },
        created:function(){
            const user = Cookies.get("name");
            
            console.log(user);
            this.username = user;
        },
        setup() {
            
            const router = useRouter()
            const state = reactive({
                tabsActive: 'notice',
                notices: [],
                news: [],
                untreateds: [],
            })

            onMounted(()=>{
                state.notices = [
                    /*
                    {id:1,icon:'el-icon-chat-dot-round',message:'通知',dateTime:'2021-01-22 12:15:21'},
                    {id:2,icon:'el-icon-chat-dot-round',message:'通知',dateTime:'2021-01-22 12:15:21'},
                    {id:3,icon:'el-icon-chat-dot-round',message:'通知',dateTime:'2021-01-22 12:15:21'},
                    {id:4,icon:'el-icon-chat-dot-round',message:'通知',dateTime:'2021-01-22 12:15:21'},*/
                ]

                state.news = [
                    {id:1,icon:'el-icon-chat-dot-round',message:'消息',dateTime:'2021-01-22 12:15:21'},
                    {id:2,icon:'el-icon-chat-dot-round',message:'消息',dateTime:'2021-01-22 12:15:21'},
                    {id:3,icon:'el-icon-chat-dot-round',message:'消息',dateTime:'2021-01-22 12:15:21'},
                    {id:4,icon:'el-icon-chat-dot-round',message:'消息',dateTime:'2021-01-22 12:15:21'},
                ]

                state.untreateds = [
                    {id:1,icon:'el-icon-chat-dot-round',message:'待办',dateTime:'2021-01-22 12:15:21'},
                    {id:2,icon:'el-icon-chat-dot-round',message:'待办',dateTime:'2021-01-22 12:15:21'},
                    {id:3,icon:'el-icon-chat-dot-round',message:'待办',dateTime:'2021-01-22 12:15:21'},
                    {id:4,icon:'el-icon-chat-dot-round',message:'待办',dateTime:'2021-01-22 12:15:21'},
                ]
            })

            const onManage = (id) =>{
                console.log(id)
            }

            const onTab = (tab, event) => {
                console.log(tab, event);
            }

            const logout = () => {
                console.log("logout");
                Cookies.remove('token');
                Cookies.remove('name');
                Cookies.remove('patharr');
                Cookies.remove('ismerchant');
                Cookies.remove('mid');
                
                router.push({path: '/'});
            }
            const personinfo = () => {
                router.push({path:'/personinfo'});
            }
            const onPassword = () => {
                router.push({path: 'user-password'})
            }

            return {
                ...toRefs(state),
                personinfo,
                logout,
                onPassword,
                onTab,
                onManage
            }
        },
        
    }
</script>

<style>
    .head-menu {
        cursor: pointer;
    }
    .head-menu .el-badge__content.is-fixed.is-dot {
        top: 15px;
    }
</style>