<template>
    <el-container class="container">
        <el-aside :width="asideWidth">
            <el-menu class="aside-menu" router :default-active="$route.path" :collapse="isCollapse"
                     background-color="#F0F7FF" text-color="#757575" active-text-color="#409eff">
                <div v-for="menu in routers" :key="menu">
                    <div v-if="ismerchant == 6">
                        <div
                             v-if="menu.path == '/order' || menu.path == '/finance' || menu.path == '/permission' || menu.path == '/main'">
                            <el-menu-item v-if="menu.children && menu.children.length === 1"
                                          :index="menu.children[0].path">
                                <i :class="menu.children[0].icon"></i>
                                <template #title>
                                    {{ menu.children[0].name }}
                                </template>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children" :index="menu.path">
                                <template #title>
                                    <i :class="menu.icon"></i>
                                    <span :class="[isCollapse ? 'is-collapse' : '']">{{
                                        menu.name
                                        }}</span>
                                </template>
                                <el-menu-item v-for="child in menu.children" :key="child" :index="child.path"
                                              v-show="(child.path == '/bill' || child.path == '/settle' || child.path == '/order' || child.path == '/buyout' || child.path == '/salesman' || child.path == '/home' || child.path == '/recharge' || child.path == '/cashout' || child.path == '/rechargedetail') && child.meta.show">
                                    <div>
                                        <i :class="child.icon"></i>
                                        {{ child.name }}
                                    </div>
                                </el-menu-item>
                            </el-sub-menu>
                        </div>
                    </div>
                    <div v-else-if="ismerchant == 7">
                        <div v-if="menu.path == '/main'">
                            <el-menu-item v-if="menu.children && menu.children.length === 1"
                                          :index="menu.children[0].path">
                                <i :class="menu.children[0].icon"></i>
                                <template #title>
                                    {{ menu.children[0].name }}
                                </template>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children" :index="menu.path">
                                <template #title>
                                    <i :class="menu.icon"></i>
                                    <span :class="[isCollapse ? 'is-collapse' : '']">{{
                                        menu.name
                                        }}</span>
                                </template>
                                <el-menu-item v-for="child in menu.children" :key="child" :index="child.path"
                                              v-show="child.path == '/home'">
                                    <div>
                                        <i :class="child.icon"></i>
                                        {{ child.name }}
                                    </div>
                                </el-menu-item>
                            </el-sub-menu>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="menu.path != '/SparkFire'">
                            <el-menu-item v-if="menu.children && menu.children.length === 1"
                                          :index="menu.children[0].path">
                                <el-icon>
                                    <component :is="menu.children[0].icon" />
                                </el-icon>
                                <template #title>
                                    {{ menu.children[0].name }}
                                </template>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children" :index="menu.path">
                                <template #title>
                                    <el-icon>
                                        <component :is="menu.icon" />
                                    </el-icon>
                                    <span :class="[isCollapse ? 'is-collapse' : '']">{{
                                        menu.name
                                        }}</span>
                                </template>
                                <el-menu-item v-for="child in menu.children" :key="child" :index="child.path"
                                              v-show="child.meta.show == true && ismerchant != 1">
                                    <div>
                                        <i :class="child.icon"></i>
                                        {{ child.name }}
                                    </div>
                                </el-menu-item>
                            </el-sub-menu>
                        </div>
                    </div>
                </div>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header>
                <span style="display: flex;line-height: 60px;cursor: pointer;" @click="onCollapse">
                    <el-icon v-if="!isCollapse">
                        <Fold />
                    </el-icon>
                    <el-icon v-if="isCollapse">
                        <Expand />
                    </el-icon>
                </span>
                <el-breadcrumb style="padding: 0 10px;" separator="/" v-if="this.$route.matched[0].path != '/main'">
                    <el-breadcrumb-item :to="{ path: '/' }">工作台</el-breadcrumb-item>
                    <el-breadcrumb-item v-for="(matched, m) in this.$route.matched" :key="m">
                        {{ matched.name }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb style="padding: 0 10px;" separator="/" v-else>
                    <el-breadcrumb-item>数据参谋</el-breadcrumb-item>
                </el-breadcrumb>
                <div style="display: flex;justify-content:flex-end;flex:1;">
                    <HeadMenu />
                </div>
            </el-header>
            <el-main :style="defaultHeight">
                <router-view />
            </el-main>
        </el-container>
        <el-backtop target=".el-main"></el-backtop>
    </el-container>
</template>
<!-- <template>
    <el-container class="container">
        <el-aside :width="asideWidth">
            <el-menu class="aside-menu" :collapse="isCollapse" router :default-active="$route.path"
                     background-color="#F0F7FF" text-color="#757575" active-text-color="#409eff">
                <div v-for="menu in routers" :key="menu">
                    <div v-if="ismerchant == 6">
                        <div v-if="
                            menu.path == '/order' ||
                            menu.path == '/finance' ||
                            menu.path == '/permission' ||
                            menu.path == '/main'
                        ">
                            <el-menu-item v-if="menu.children && menu.children.length === 1"
                                          :index="menu.children[0].path">
                                <el-icon>
                                    <component  :is="ElementPlusIconsVue[menu.children[0].icon]" />
                                </el-icon>
                                <template #title>
                                    {{ menu.children[0].name }}
                                </template>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children" :index="menu.path">
                                <template #title>
                                    <el-icon>
                                        <component :is="ElementPlusIconsVue[menu.icon]" />
                                    </el-icon>
                                    <span :class="[isCollapse ? 'is-collapse' : '']">{{
                                        menu.name
                                    }}</span>
                                </template>
                                <el-menu-item v-for="child in menu.children" :key="child" :index="child.path" v-show="(child.path == '/bill' ||
                                    child.path == '/settle' ||
                                    child.path == '/order' ||
                                    child.path == '/buyout' ||
                                    child.path == '/salesman' ||
                                    child.path == '/home' ||
                                    child.path == '/recharge' ||
                                    child.path == '/cashout' ||
                                    child.path == '/rechargedetail') &&
                                    child.meta.show
                                    ">
                                    <div>
                                        <el-icon>
                                            <component :is="ElementPlusIconsVue[child.icon]" />
                                        </el-icon>
                                        {{ child.name }}
                                    </div>
                                </el-menu-item>
                            </el-sub-menu>
                        </div>
                    </div>
                    <div v-else-if="ismerchant == 7">
                        <div v-if="menu.path == '/main'">
                            <el-menu-item v-if="menu.children && menu.children.length === 1"
                                          :index="menu.children[0].path">
                                <el-icon>
                                    <component :is="ElementPlusIconsVue[menu.children[0].icon]" />
                                </el-icon>
                                <template #title>
                                    {{ menu.children[0].name }}
                                </template>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children" :index="menu.path">
                                <template #title>
                                    <el-icon>
                                        <component :is="ElementPlusIconsVue[menu.icon]" />
                                    </el-icon>
                                    <span :class="[isCollapse ? 'is-collapse' : '']">{{
                                        menu.name
                                    }}</span>
                                </template>
                                <el-menu-item v-for="child in menu.children" :key="child" :index="child.path"
                                              v-show="child.path == '/home'">
                                    <div>
                                        <el-icon>
                                            <component :is="ElementPlusIconsVue[child.icon]" />
                                        </el-icon>
                                        {{ child.name }}
                                    </div>
                                </el-menu-item>
                            </el-sub-menu>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="menu.path != '/SparkFire'">
                            <el-menu-item v-if="menu.children && menu.children.length === 1"
                                          :index="menu.children[0].path">
                                <el-icon style="padding-left: 10px;">
                                    <component :is="ElementPlusIconsVue[menu.children[0].icon]" />
                                </el-icon>
                                <template #title>
                                    {{ menu.children[0].name }}
                                </template>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children" :index="menu.path">
                                <template #title>
                                    <el-icon>
                                        <component :is="ElementPlusIconsVue[menu.icon]" />
                                    </el-icon>
                                    <span :class="[isCollapse ? 'is-collapse' : '']">{{
                                        menu.name
                                    }}</span>
                                </template>
                                <el-menu-item v-for="child in menu.children" :key="child" :index="child.path"
                                              v-show="child.meta.show == true && ismerchant != 1">
                                    <div>
                                        <el-icon>
                                            <component :is="ElementPlusIconsVue[child.icon]" />
                                        </el-icon>
                                        {{ child.name }}
                                    </div>
                                </el-menu-item>
                            </el-sub-menu>
                        </div>
                    </div>
                </div>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header style="display: flex;align-items: center;">
                <el-icon v-if="!isCollapse" @click="onCollapse">
                    <Fold />
                </el-icon>
                <el-icon @click="onCollapse" v-else>
                    <Expand />
                </el-icon>
                <el-breadcrumb separator="/" style="flex: 80%;margin-left: 10px;"
                               v-if="$route.matched[0].path != '/main'">
                    <el-breadcrumb-item :to="{ path: '/' }">数据参谋</el-breadcrumb-item>
                    <el-breadcrumb-item v-for="(matched, m) in $route.matched" :key="m">
                        {{ matched.name }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb style="margin-left: 10px;" separator="/" v-else>
                    <el-breadcrumb-item>
                        数据参谋
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <HeadMenu />
            </el-header>
            <el-main :style="defaultHeight">
                <router-view />
            </el-main>
        </el-container>
        <el-backtop target=".el-main"></el-backtop>
    </el-container>
</template> -->
<script>
import { HomeFilled } from '@element-plus/icons-vue'
import { onBeforeMount, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import HeadMenu from "@/components/HeadMenu";
import Cookies from 'js-cookie'
export default {
	components: {
		HeadMenu,
        HomeFilled
	},
	data() {
		return {
			ismerchant: '',
			addto: true,
		}
	},
	setup() {
		const router = useRouter();
		const state = reactive({
			logo: require('@/assets/image/logo4.png'),
			logosmall: require('@/assets/image/logo5.png'),
			isCollapse: false,
			isOpen: false,
			asideWidth: "160px",
			defaultHeight: {
				height: "",
			},
			routers: [],
		});
		onBeforeMount(() => {
			state.routers = router.options.routes;
			console.log(router.options.routes)
			state.defaultHeight.height = document.body.clientHeight + "px";
		});
		const onCollapse = () => {
			if (state.isCollapse) {
				state.asideWidth = "160px";
				state.isCollapse = false;
				state.isOpen = false;
			} else {
				state.isOpen = true;
				state.isCollapse = true;
				state.asideWidth = "64px";
			}
		};
		const onRefresh = () => {
			router.push({ path: "main" });
		};
		return {
			...toRefs(state),
			onCollapse,
			onRefresh,
		};
	},
	created: function () {
		this.ismerchant = Cookies.get("IsMerchant");
	}
};
</script>
<style scoped>
.container .el-aside {
	background-color: #F0F7FF
}

.container .el-aside .el-menu {
	text-align: left;
	border-right: 0px;
}

.container .el-aside .aside-logo .logo-name {
	font-size: 15px;
}
.container .el-aside .aside-logo .logoinfo {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.container .el-aside .is-collapse {
	display: none;
}

.container .el-aside .aside-menu:not(.el-menu--collapse) {
	width: 160px;
}

.el-sub-menu .el-submenu__title .el-submenu__icon-arrow.el-icon-arrow-right {
	display: none;
}

.el-sub-menu .el-submenu__title {
	padding-left: 10px !important;
}

.el-menu-item {
	min-width: 160px !important;
}

.el-menu-item div {
	padding-left: 10px !important;
}

.container .el-aside .aside-logo {
	height: 80px;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container .el-aside .aside-logo-collapse {
	height: 60px;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container .el-aside::-webkit-scrollbar {
	width: 0px;
}

.container .el-aside {
	height: 100%;
	transition: all .5s;
	background-color: #F0F7FF;
	overflow-y: auto;
	overflow-x: hidden;
}

.container .el-header {
	background: white;
	line-height: 60px;
	font-size: 24px;
	border-bottom: 1px #cccccc solid;
}

.container .el-header.header-collapse {
	cursor: pointer;
}

.container .el-header .header-breadcrumb {
	padding-top: 0.9em;
}

.container .el-header .header-menu {
	text-align: right;
	width: 160px;
}

.container .el-main {
	padding: 0.6em;
	overflow-x: hidden;
	overflow-y: auto;
}

.container .el-main::-webkit-scrollbar {
	width: 6px !important;
	height: 1px;
}

.container .el-main::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background: rgba(0, 0, 0, 0.5);
}

.container {
	background: rgb(245, 247, 249);
}
</style>
